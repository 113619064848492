"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/es6");
require("core-js/es7/reflect");
var environment_1 = require("./environments/environment");
require('zone.js/dist/zone');
if (environment_1.environment.production) {
    // Production
}
else {
    // Development and test
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}
window.global = window;
